
/* InputField */

.generic-survey-input-filed{
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    border-color:#ffffff !important ;
    font-weight: 400;
    font-size: 1rem;
}
.generic-survey-input-filed:hover{
    border-radius: 8px;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
}
.generic-survey-input-filed:focus{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-input-filed:focus-visible{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-input-filed:focus-within{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-input-filed .ant-input-number-input{
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    font-weight: 400;
    font-size: 1rem;
    padding-left: 10px;
}
.generic-survey-input-filed .ant-input-number-handler-wrap{
    background: #ECF1F4 !important;
    border: none !important;
    border-radius: 0px 8px 8px 0px !important;
    width: 30px;
    height: 2.8rem !important;
    padding-top: 10px;
}
.generic-survey-input-filed .ant-input-number-handler-down {
    border-top: none !important;
}
.grow-survey-input-filed,.grow-survey-input-filed:hover,.grow-survey-input-filed:focus,.grow-survey-input-filed:focus-visible,.grow-survey-input-filed:focus-within{
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    border-color:#AABAC6 !important ;
    font-weight: 400;
    font-size: 1rem;
}
.grow-survey-input-filed .ant-input-number-input{
    border-radius: 8px !important;
    height: 2.75rem;
    width:100% !important;
    font-family:'Poppins';
    font-weight: 400;
    font-size: 1rem;
    padding-left: 10px;
}
.grow-survey-input-filed .ant-input-number-handler-wrap{
    background: #ECF1F4 !important;
    border: none !important;
    border-radius: 0px 8px 8px 0px !important;
    width: 30px;
    height: 2.8rem !important;
    padding-top: 10px;
}
.grow-survey-input-filed .ant-input-number-handler-down {
    border-top: none !important;
}

/* InputColor */

.sketch-trigger-button{
    background: #B0CB1F;
    width: 111px;
    height: 1.5rem;
    border-radius: 6px;
    border: none;
    box-shadow: none;
    margin-right: 10px !important;
}

.picked-color-text{
    font-family:'Poppins';
    font-weight: 400;
    color: #000;
    font-size: 1rem;
    padding-top: 3px;
}
.color-picker-container-max-width{
    max-width: 300px;
    width: 100%;
}

/* InputRange */

.generic-survey-range-slider .ant-slider-rail{
    height: 10px !important;
    background: #A9A7A7 !important;
    border-radius: 20px;
}
.generic-survey-range-slider .ant-slider-track{
    height: 10px !important;
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important;
    border-radius: 20px;
    /* transition:  0.2s !important; */
}
.generic-survey-range-slider .ant-slider-handle{
    background: linear-gradient(180deg, #00CA64 0%, #4CCB1F 100%) !important; 
    border: none;
    width: 20px;
    height: 20px;
    /* transition: 0.1s !important; */
}

.not-answered-slider .ant-slider-handle{
    background: #b3b1b1 !important; 
}

.generic-survey-range-slider .ant-slider-dot{
    display: none !important;
}
.generic-survey-range-slider .ant-slider-mark-text{
    margin-top: 5px !important;
    color: #000000;
    font-family:'Poppins';
    font-size: 1rem !important;
    font-weight: 500;
}

/* InputDatePicker */

.generic-survey-date-picker-custom{
    border: 1px solid #DDDDDD;
    border-radius: 8px !important;
    font-family:'Poppins';
    font-weight: 400;
    font-size: 1.12rem;
    width: 100%;
    background-color: #fff;
    color: #303030;
}
.generic-survey-date-picker-custom:hover{
    border-radius: 8px;
    border-color: #31D0AA;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
}
.generic-survey-date-picker-custom:focus{
    border-radius: 8px;
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2) !important;
}
.generic-survey-date-picker-custom:focus-visible{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-date-picker-custom:focus-within{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-date-picker-date {
    border-radius: 8px !important;
    border: 1px solid #31D0AA!important;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
    margin-top: 30px !important;
}
.generic-survey-date-picker-date .ant-picker-panel-container{
    border-radius: 8px !important;
}
.generic-survey-date-picker-date .ant-picker-content{
    color: #333333 !important;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 14px !important;
}
/* .generic-survey-date-picker-date .ant-picker-footer{
    display: none !important;
} */

.generic-survey-date-picker-date .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border: 1px solid #4CCB1F !important;
}
.generic-survey-date-picker-date .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
    background:#4CCB1F ;
}
.generic-survey-date-picker-date .ant-picker-today-btn{
    color: #4CCB1F !important;
}

.generic-survey-date-picker-date .ant-picker-content .ant-picker-week-panel-row-selected td {
    background:#4CCB1F !important;
}
/* InputTimePicker */

.survey-time-select-picker .ant-select-selector{
    background: #FFFFFF;
    border: 1px solid #FFFFFF !important;
    border-radius: 8px !important;
    height: 40px !important;
    
    
}
.survey-time-select-picker .ant-select-selector:hover{
    border-radius: 8px;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
    
}
.survey-time-select-picker .ant-select-selector:focus{
    border-radius: 8px;
    border-color: #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
    
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-search-input{
    height: 40px !important;
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-placeholder{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #d0d0d0 !important;
    padding-left: 0px !important;
    padding-top: 4.5px !important;
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{
    height: 40px !important;
}
.survey-time-select-picker .ant-select-selector .ant-select-selection-item{
    height: 40px !important;
    padding-top: 4.5px !important;
}
.survey-time-drop-item{
    font-family: 'Poppins';
    font-weight: 500;
}

/* InputRadio button */
.survey-radio-choice p{
    font-family: 'poppins';
    font-weight: 500;
    font-size: 1rem !important;
    /* line-height: 1,25rem; */
    /* color: #0000; */
    margin: 2px 0px;
}
.survey-radio-choice {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 1rem !important;
    /* line-height: 1,25rem; */
    /* color: #0000; */
    margin: 2px 0px;
}
.survey-radio-choice .ant-radio-checked .ant-radio-inner {
    border-color: #4CCB1F !important;
    border: 1px solid #4CCB1F !important;
}
.survey-radio-choice .ant-radio-inner::after{
    background-color: #4CCB1F !important;
    top: 4px !important;
    left: 4px !important;
    width: 10px !important ;
    height: 10px !important;
}
.survey-radio-choice .ant-radio-input{
    width: 0px !important;
    height: 0px !important;
}
.survey-radio-choice .ant-radio-inner{
    border-width: 1px !important;
}
.survey-radio-choice .ant-radio-inner:hover{
    border: 1px solid #4CCB1F !important;
}
.survey-radio-choice .ant-radio-inner:focus{
    border: 1px solid #4CCB1F !important;
}
.survey-radio-choice .ant-radio-inner::before{
    border-color: #4CCB1F !important;
}


.survey-radio-choice .ant-radio:hover{
    border-color: #4CCB1F !important;
}
.survey-radio-choice .ant-radio:focus{
    border-color: #4CCB1F !important;
}
.survey-radio-choice .ant-radio::before{
    border-color: #4CCB1F !important;
}

/* InputCheckBox */

.survey-check-box-content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}
.survey-check-box-content p{
    margin-bottom: -5px !important;
    font-family: 'poppins';
    font-weight: 500;
    /* font-size: 1rem !important;
    line-height: 1,25rem;
    color: #0000; */
}
.survey-check-box-content .ant-checkbox{
    padding: 3px !important;
    border: 1px solid #8C8CA1;
    border-radius: 4px !important;
    background-color: #F4F9FD !important;
}
.survey-check-box-content .ant-checkbox-checked{
    border: 1px solid #4CCB1F !important;
}
.survey-check-box-content .ant-checkbox:focus{
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2) !important;
}
.survey-check-box-content .ant-checkbox:hover {
    border: 1px solid #31D0AA!important;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2);
}
.survey-check-box-content .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #4CCB1F !important;
}
.survey-check-box-content .ant-checkbox-inner{
    border: none !important;
}
.survey-check-box-content .ant-checkbox-checked::after{
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2) !important;
}
.survey-check-box-content .ant-checkbox-checked::after .ant-checkbox {
    border: none !important;
}


.generic-survey-select-filed .ant-select-selector{
    background: #fff !important;
    border-radius: 8px !important;
    height: 2.75rem !important;
    width:100% !important;
    font-family:'Poppins';
    border: 1px solid #FFF ;
    font-weight: 400;
    font-size: 1rem;
}
.grow-surevey-select-filed .ant-select-selector,
.grow-surevey-select-filed .ant-select-selector:hover,
.grow-surevey-select-filed .ant-select-selector:focus,
.grow-surevey-select-filed .ant-select-selector:focus-visible,
.grow-surevey-select-filed .ant-select-selector:focus-within{
    border: 1px solid #AABAC6 !important;
    height: 2.5rem !important;
    background: #fff !important;
    border-radius: 8px !important;
    width:100% !important;
    font-family:'Poppins';
    font-weight: 500;
    font-size: 1rem;
    border-color: #AABAC6;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.grow-surevey-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 2.5rem !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.grow-surevey-select-row .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 1px solid #AABAC6 !important;
}
.grow-surevey-select-row  .ant-select-dropdown{
    box-shadow: none !important;
}
.generic-survey-select-filed .ant-select-selector:hover{
    border-radius: 8px;
    border-color: #31D0AA;
    border: 1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.generic-survey-select-filed .ant-select-selector:focus{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    border: 1px solid #31D0AA ;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-select-filed .ant-select-selector:focus-visible{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    border: 1px solid #31D0AA ;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-select-filed .ant-select-selector:focus-within{
    border-radius: 8px !important;
    border-color: #31D0AA !important;
    border: 1px solid #31D0AA !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2)!important;
}
.generic-survey-select-filed .ant-select-selector .ant-select-selection-search-input{
    height: 2.75rem !important;
}
.generic-survey-selected-row  .ant-select-dropdown{
    box-shadow: none !important;
}
.generic-survey-selected-row .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: 1px solid #ffffff !important;
}
.selected-survey.ant-select-item{
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #000;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    
}
.selected-survey.ant-select-item p{
    margin: 0px;
}
.selected-survey.ant-select-item:hover{
    font-family: 'Poppins';
    color: #000 !important;
    background-color: #ECF1F4 !important;
    padding-top: 5px !important;
    border:1px solid #31D0AA !important;
    outline: 0;
    /* box-shadow: 0 0 0 2px rgba(49, 208, 170, 0.2); */
}
.selected-survey.ant-select-item:focus{
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
    
}
.selected-survey.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: #fff !important;
    background-color: #4CCB1F !important;
    padding-top: 5px !important;
}
.grow-surevey-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: -1px !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1rem;
    margin: 0px;
}
.generic-survey-select-filed .ant-select-selector .ant-select-selection-placeholder{
    padding-top: 0px !important;
    color: #8C8CA1 !important;
    font-weight: 500;
    font-size: 1rem;
}
.grow-surevey-select-filed .ant-select-selector .ant-select-selection-item{
    padding-top: 5px !important;
    font-weight: 500;
    font-size: 1rem;
}
.generic-survey-select-filed .ant-select-selector .ant-select-selection-item{
    padding-top: 6px !important;
    font-weight: 500;
    font-size: 1rem;
}
.no-shadow-inputs:hover{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-shadow-inputs:focus{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-shadow-inputs:focus-visible{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-shadow-inputs:focus-within{
    border-color: #fff !important;
    outline: 0;
    box-shadow:none !important;
}
.no-border .ant-select-selector{
    border: none !important;
}
.no-shadow-inputs .ant-select-selector:hover{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.no-shadow-inputs .ant-select-selector:focus{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.no-shadow-inputs .ant-select-selector:focus-visible{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.no-shadow-inputs .ant-select-selector:focus-within{
    border-color: #fff !important;
    outline: none !important;
    box-shadow:none !important;
}
.generic-survey-input-filed .ant-input-number-handler-wrap{
    display: none !important;
}

/* Survey Skin Stepper */
.generic-survey-skil-stepper{
    padding: 2rem 4rem;
}
.generic-survey-skil-stepper .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
    background: #B0CB1F !important;
    /* width: 18px !important;
        height: 18px !important; */

}
.generic-survey-skil-stepper .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot{
        background:#4CCB1F;
        /* width: 12px !important;
        height: 12px !important; */
}
.generic-survey-skil-stepper .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot{
    left: 0 !important;
}
.generic-survey-skil-stepper .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon > .ant-steps-icon{ 
    /* margin-top: 8px; */
    /* background: #fff;
    border: 1px solid #4CCB1F;
    width: 18px;
    height: 18px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 50%; */
    /* padding: 2px !important; */
    }
.generic-survey-skil-stepper .ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot{
    /* margin: 4px !important; */
}
.generic-survey-skil-stepper .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after{
    background-color:  #B0CB1F !important;
    /* width: 20px !important;
    height: 20px !important; */
    height: 85%;
}
.generic-survey-skil-stepper .ant-steps-dot .ant-steps-item-tail::after{
    margin-top: 15px;
    margin-left: 13px !important;
}
.generic-survey-question-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 20px;
    margin-top: 2.5rem;
    transition: all 0.5s ease;
}
.generic-survey-question{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 !important;
}
.generic-survey-question p{
    margin: 0 !important;  
}



.survey-upload-seaction .ant-upload.ant-upload-select-picture-card{
    border: none !important;
    background-color: transparent !important;
}
.survey-metrix-col-data{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1rem;
    color: #000;
    margin: 0px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.survey-metrix-col-data p{
    margin: 0px !important;
}
.generic-survey-radio-choice-container{
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    align-items: center;
}
.generic-survey-action-btn, .generic-survey-action-btn:focus, .generic-survey-action-btn:hover, .generic-survey-action-btn:active{
    border-radius: 8px;
    background-color: #4CCB1F;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 700;
    color: #FFFFFF;
    font-size: 1.2rem;
    padding: 10px 30px;
    cursor: pointer;
    border: none !important;
    height: 50px !important;
}
.generic-survey-help-text-popover .ant-popover-inner{
    background: #0E0E2C;
}
.generic-survey-help-text-popover .ant-popover-content > .ant-popover-arrow{
    border-right-color: #0E0E2C;
    border-bottom-color: #0E0E2C;
}
.generic-survey-help-text{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: .875rem;
    color: #FAFCFE;

}
.generic-survey-help-text-btn{
    background-color: #4CCB1F;
    color: #fff;
    border-radius: 6px;
    padding: 6px 15px;
    cursor: pointer;
    font-weight: 700;
    font-size: .875rem;
    border: none;
}
.generic-survey-quit-btn, .generic-survey-quit-btn:hover, .generic-survey-quit-btn:focus{
    background:#f67927;
     /* #E13F3F; */
    border-radius: 8px;
    border: none;
    font-size: 'Poppins';
    font-weight: 500;
    font-size: 1rem;
    padding: 4px 15px;
    color: #fff;
    height: 50px;
}
.generic-survey-quit-title{
    font-size: 1.2rem;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 1.5rem;
}
.generic-survey-quit-para{
    font-size: .875rem;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1.35rem;
}
.generic-survey-remove-btn{
    background-color: #fff;
    border: 1px solid #8C8CA1;
    border-radius: 8px;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #8C8CA1;
    cursor: pointer;
}
.generic-survey-remove-btn:hover{
    background-color: #8C8CA1;
    border: 1px solid #8C8CA1;
    color: #fff;
    cursor: pointer;
}
.generic-survey-submit-btn{
    background-color: #fff;
    border: 1px solid #4CCB1F;
    border-radius: 8px;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #4CCB1F;
    cursor: pointer;
}
.generic-survey-submit-btn:hover{
    background-color: #4CCB1F;
    border: 1px solid #4CCB1F;
    color: #fff;
    cursor: pointer;
}

.generic-survey-ok-button, .generic-survey-ok-button:hover, .generic-survey-ok-button:focus, .generic-survey-ok-button:active {
    background-color: #4CCB1F;
    color: #fff !important;
    border-radius: 8px;
    border: none;
    padding: 9px 15px;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    cursor: pointer;
    width: 4.6rem;
}


.generic-survey-assessment-heading{
    font-size: 2rem;
    font-weight: 700;
    color: #141414;
    font-family: 'Poppins';
    line-height: 2.5rem;
    margin: 0 !important;
}

@media (max-width:600px) {
    .generic-survey-skil-stepper{
        padding: 1rem 2rem;
    } 
    .generic-survey-range-slider{
        padding: 0px !important;
    }
}
@media (max-width:480px) {
    .generic-survey-skil-stepper{
        padding: 1rem 1rem;
    } 
}

.survey-group-title{
    font-size: 1.5rem;
    font-family: 'Poppins';
    font-weight: 700;
    line-height: 2rem;
    color: #141414;
}
.survey-ck-editior-view .cke_chrome{
    border-radius: 8px !important;
    border: 1px solid #ffffff !important;
}
.survey-ck-editior-view .cke_bottom{
    display: none !important;
}
.survey-ck-editior-view .cke_top{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid #ECF1F4 !important;
}
.cke_dialog_body{
    border-bottom: 16px !important;
    background-color: #ffffff !important;
    
}
.cke_dialog_title{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1rem !important;
    line-height: 1.25rem;
    color: #000;
}
.cke_dialog_ui_input_text{
    height: 3rem !important;
    border-radius: 8px !important;
    border: 1px solif #ECF1F4 !important;
    background-color: #ffffff;
}
.cke_dialog_ui_labeled_label{
    font-size: .875rem;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 1rem;
    color: #141414;
}
.cke_dialog_contents_body .cke_dialog_ui_select{
display:  none !important;
}
.cke_dialog_ui_hbox_first{
    width: 0px !important;
    padding-right: 0px !important;
}
.cke_dialog_ui_hbox_last{
    width: 100% !important;
}
.cke_dialog_ui_input_select{
    height: 3rem !important;
    border-radius: 8px !important;
    border: 1px solif #ECF1F4 !important;
    background-color: #ffffff;
}
.cke_dialog_footer{
    border-top: none !important;
}
.cke_dialog_footer_buttons{
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
    gap: 10px !important;
}
.cke_dialog_ui_button_ok{
    background: #4CCB1F !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    font-family: 'Poppins'!important;
    color: #ffffff !important;
    padding: 8px 20px;
    border-radius: 8px !important;
    border: 1px solid #4CCB1F !important;
    height: 100% !important;
    margin-right: 10px !important;
}
.cke_dialog_ui_button_cancel{
    background: #ffffff !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    font-family: 'Poppins'!important;
    color: #8C8CA1 !important;
    padding: 8px 20px;
    border-radius: 8px !important;
    border: 1px solid #8C8CA1!important;
    height: 100% !important;
}
.cke_dialog_tabs{
    display: none !important;
}
.cke_dialog_contents{
    margin-top: 0px !important;
    border: none !important;

}
.cke_dialog_contents_body{
    height: 100% !important;
}
.cke_dialog .cke_resizer_ltr{
    display: none !important;
}
.generic-survey-audio-input{
    border-radius: 16px;
    width: 100%;
    background: #ffffff;
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.audio-titmer-text{
    font-size: 3rem;
    font-weight: 500;
    font-family: 'Poppins';
    color: #000;
    line-height: 4rem;
}

.generic-survey-next-btn, .generic-survey-next-btn:hover, .generic-survey-next-btn:active, .generic-survey-next-btn:focus{
    border-radius: 8px;
    background-color: #4CCB1F;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 700;
    color: #FFFFFF;
    font-size: 1.2rem;
    padding: 10px 30px;
    height: 50px;
    cursor: pointer;
    border: none !important;
    height: 50px !important;
}

.color-picker-extend-height{
    height: 300px;
    transition-duration: .5s;
}

.color-picker-default-height{
    height: 35px;
    transition-duration: .5s;
}

.sketch-picker-default-height{
    opacity: 0 !important;
    margin-top: 10px;
    width: 95% !important;
    overflow: auto;
    height: 0px;
    transition-duration: .5s;
}

.sketch-picker-extend-height{
    transition-duration: .5s;
    opacity: 1 !important;
    margin-top: 10px;
    width: 95% !important;
    overflow: auto;
    height: 250px;
}

.generic-survey-date-picker-date .ant-picker-ok button, .generic-survey-date-picker-date .ant-picker-ok button:hover{
    border-radius: 4px;
    background-color: #4CCB1F;
    color: #fff;
}

.ant-picker-now-btn, .ant-picker-now-btn:hover{
    color: #4CCB1F;;
}

.survey-matrix-main-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1em;
}

.survey-matrix-question-container{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.survey-matrix-sections{
    height: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.survey-matrix-table-head{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
}

.question-input div{
    display: flex;
}

.generic-survey-question-preview-container{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    padding: 1.25em; 
    background-color: rgba(246, 251, 255, 1);
}

.survey-preview-main-alignment-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 1.5em;
}

.survey-preview-main-section{
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 90%;
    background-color: #fff;
    padding: 1.5em;
    border-radius: 8px;
    margin-top: 1em; 
}

 