.kudos-card-root{
    font-size: 16px;
    font-family: Poppins;
}
.kudos-card-container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 2em;
    width: 100%;
}
.kudos-new-card{
    border: 1px solid #AABAC6 !important;
    border-radius: 1em !important;
    background: #ffff;
    width: 100%;
    padding: 1em;
    height: 100%;
    min-height: 30em;
}
.kudos-card-user-name{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    margin: 0px;
}
.kudos-card-user-role{
    font-family: Poppins;
    font-size: .95em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    margin: 0px;
    color: #8998A2;
}
.kudos-card-detail-container{
    margin: 1em 3em;
    height: 100%;
    border-radius: 1em;
    min-height: 20em;
    padding: 3em 2em;
}
.kudos-card-detail-title{
    font-family: Poppins !important;
    font-size:1.75em;
    font-weight: 500 !important;
    color: #fff;
    font-style: normal;
    margin: 0px;
}
.kudos-card-detail-description{
    font-family: Poppins;
    font-size:1em;
    font-weight: 400;
    color: #fff;
    font-style: normal;
    margin: 0px;
    padding-top: .5em;
}
.kudos-card-award-btn{
    box-shadow: -10px -10px 20px 0px rgba(250, 255, 255, 0.25);     
    font-size: 1em;
    font-family: Poppins;
    font-size: 1.2em;
    padding: .75em 1em;
    width: fit-content;
    color: #fff;
    border-radius: 1em;

}
.kudos-card-badge-image{
    width: 11.5em;
    height: 12em;
    object-fit: contain;

}
.kudos-card-comment-section{
    width:100%;
    height: auto;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap:.5em
}

.kudos-card-text-overflow{
  overflow: scroll;
  max-height: 14em;
  min-height: 10em;
  overflow-wrap: anywhere;  
  max-width: 100%;
}

.Kudos-card-badge-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1em;
}
.Kudos-card-badge-image-container-inner{
    position: relative;
    width: 100%;
    height: 100%;
}  
.Kudos-card-badge-image-container-inner:hover .Kudos-card-badge-image-container-inner-front {
    transform: rotateY(180deg);
}
  
.Kudos-card-badge-image-container-inner:hover .Kudos-card-badge-image-container-inner-back {
transform: rotateY(0);
}

.Kudos-card-badge-image-container-inner-front, .Kudos-card-badge-image-container-inner-back{
width: 100%;
height: 100%;
backface-visibility: hidden;
display: flex;
align-items: center;
justify-content: center;
transition: transform 0.6s ease-in-out;
backface-visibility: hidden;
}
.Kudos-card-badge-image-container-inner-back{
transform: rotateY(180deg);
position: absolute;
top:0;
}

.kudos-card-filter-text {
    color: rgba(26, 26, 26, 1);
    font-size: 1em !important;
    font-family: "Poppins";
    line-height: normal;
    font-style: normal;
    line-height: normal;
    font-weight: 400;
  }
  .user-kudos-badge-select .ant-select-selector{
    background-color: #ffff !important;
    border: 1px solid rgba(170, 186, 198, 1) !important;
    border-radius: 8px !important;
  }
.kudos-search-imput-popup,
.kudos-search-imput-popup:hover,
.kudos-search-imput-popup:focus{
    border: none !important;
    box-shadow: none !important;
}
.new-kudos-badge-award-carosul-container{
    border: 1px solid rgba(170, 186, 198, 1);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width:100% !important;
    min-width: 100% !important;
    max-width: 42em!important;
    height: 10em !important;
    padding: 0px 1em;
    border-radius: .5em;
    margin-top: 1em;
  }
  .new-kudos-badge-award-carosul-container .ant-spin-nested-loading{
    width: 100% !important;
  }
  .new-kudos-badge-award-carosul-container-loading{
    border: 1px solid rgba(170, 186, 198, 1);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      width:100% !important;
      min-width: 100% !important;
      max-width: 42em!important;
      height: 10em !important;
      padding: 0px 1em;
      border-radius: .5em;
  }
  .new-kudos-badge-award-carosul-container .slick-next{
    right: 0px !important;
  }
  .new-kudos-badge-award-carosul-container .slick-prev{
    left: -4px !important;
  }
  .new-kudos-user-award-badge-image-selected-container{
    height:8.5em;
    width:7.5em !important;
    border-radius: 8px;
    border: 1px solid #AABAC633;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-top: 1em;
    cursor: pointer;
  }
  .new-kudos-user-award-badge-image-selected-container:hover{
    border: 1px solid #4CCB1F;
  }
  /* .dashboard-show-kudos-detail-card{
    display: none !important;
  }
  .new-kudos-user-award-badge-image-selected-container:hover .dashboard-show-kudos-detail-card{
    display: block;
  }
  .new-kudos-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected{
    display: none;
  }
  .new-kudos-user-award-badge-image-selected-container:hover .dashboard-user-award-badge-image-selected-title{
    display: none;
  } */
  .new-kudos-user-award-badge-image-selected-container-selected{
    border: 2px solid #4CCB1F;
  }
  .give-kudos-popup-container-scroll{
    overflow: auto;
    max-height: 74vh;
    padding-right: 10px;
  }
  .give-kudos-popup-container-scroll::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
    display:block;
}
.give-kudos-popup-container-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
    }
    
.give-kudos-popup-container-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
}
.kudos-custom-date{
  margin-top: -15px;
  padding: 0px;
  font-family: 'Poppins';
  font-size: 12px;
  max-width: 440px;
  z-index: 1260 !important;
}
.kudos-custom-date .ant-picker-panel-container{
  box-shadow:none;
  border-radius: none;
  border: none;   
  margin-left: 3px !important;
  position: absolute;
  top:-300px !important;
}
.kudos-custom-date .ant-picker-range-arrow{
  display: none;
}
.kudos-custom-date .ant-picker-range-wrapper{
  /* min-width: 300px; */
  max-width: 540px;
}
.kudos-custom-date .ant-picker-date-panel .ant-picker-content{
  width:200px !important;
}
.kudos-custom-date .ant-picker-cell {
  padding: 0px;
}
.kudos-custom-date .ant-picker-date-panel .ant-picker-content th {
  width:fit-content;
}
.kudos-custom-date .ant-picker-date-panel{
  width:fit-content;
}
.kudos-reaction-card{
  box-shadow: 0px 4px 22px 3px rgba(137, 152, 162, 0.3);
  background: rgba(255, 255, 255, 1);
  padding: .75em 2em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 1em;
}
.kudos-react-icon{
  height: 3em;
  width: 3em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kudos-react-gif{
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
  display: none;
}
.kudos-react-icon .kudos-react-icon-img{
  display: block;
  height: 2.25em;
  width: 2.25em;
}
.kudos-react-icon .kudos-react-gif{
  display: none;
}
.kudos-react-icon:hover .kudos-react-icon-img{
  display: none;
}
.kudos-react-icon:hover .kudos-react-gif{
  display: block;
  height: 2.75em !important;
  width: 2.75em !important;
}
/* .kudos-react-icon-party{
  display: block !important;
}
.kudos-react-icon-party:hover{
  display: none !important;
}
.kudos-react-gif-party{
  display: none !important;
}
.kudos-react-icon-party:hover .kudos-react-gif-party{
  display: block !important;
  } */
/* .kudos-react-icon-like:hover{
  display: none;
  }
.kudos-react-icon-heart:hover{
  display: none;
  } */

/* .kudos-react-icon-like:hover .kudos-react-gif-like{
  display: block;
  }
.kudos-react-icon-heart:hover .kudos-react-gif-heart{
  display: block;
  } */
 
.kudos-card-action-btn{
  width: 10em;
  font-size: 1.25em !important;
  font-weight: 500 !important;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  padding: 0.5em 1em;
}
.kudos-card-action-btn-active {
  background-color: rgba(170, 186, 198, 0.2);
  border-radius: 0.2em;
  width: 10em;
  font-size: 1.25em !important;
  font-weight: 500 !important;
  cursor: pointer;
  text-align: center;
  padding: 0.5em 1em;
}
.kudos-comment-input,.kudos-comment-input:hover,.kudos-comment-input:focus{
  width: 100%;
  background-color: rgba(248, 248, 248, 1);
  box-shadow: 0px 0px 2px -1px rgba(0, 0, 0, 0.47) inset;
  font-family: Poppins;
  font-size: 1em;
  font-weight: 400;
  padding-right: 80px;
  height: 3em;
  border-radius: .5em;
}
.kudos-emoji-picker-container{
  width:100%;
  max-width: 30em;
  position: absolute;
  bottom: 50px;
  right: 10px;
  background-color: #fff;
  border-radius: .5em;
  display: flex;
  flex-wrap: wrap;
  gap:.25em;
  z-index: 1000;
  max-height: 200px;
  overflow: auto;
  padding: 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.kudos-badge-comment-section-scroll{
  height:100%;
  max-height:30em;
  overflow:auto;
}

.kudos-badge-comment-section-scroll::-webkit-scrollbar {
  height: 4px;
  width: 4px !important;
  display:block;
}
.kudos-badge-comment-section-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow:  inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom:10px;
  margin-top:10px
  }
  
.kudos-badge-comment-section-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#b1b0b0e1;
}
.kudos-left-board-container-responseive{
  display: none !important;
}
.kudos-left-board-cards-responseive{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 2em;
  width: 100%;
  margin-bottom:2em;
}
@media (max-width:1400px) {
  .kudos-card-root{
    font-size: 14px;
    font-family: Poppins;
}
}
@media (max-width:960px) {
  .kudos-card-root{
    font-size: 12px;
    font-family: Poppins;
}
}
@media (max-width:600px) {
  .kudos-card-root{
    font-size: 10px;
    font-family: Poppins;
}
}
@media (max-width:480px) {
  .kudos-card-root{
    font-size: 8px;
    font-family: Poppins;
}
}
@media (max-width:1100px) {
  .kudos-card-section-list-container{
    width:100% !important
  }
  .kudos-left-board-container{
    display: none !important;
  }
  .kudos-left-board-container-responseive{
    display: block !important;
  }
}
@media (max-width:700px) {
  .kudos-left-board-cards-responseive{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;
    width: 100%;
    margin-bottom:2em;
  }
}