.bottom-center {
  left: 50%;
  transform: translateX(-50%);
}

.bottom-left {
  left: 0px;
}

.bottom-right {
  right: 0px;
}

.global-buttons-icon {
  width: 52px;
  height: 52px;
  filter: drop-shadow(0px 0px 10px rgba(100, 100, 111, 0.3));
}

.ai-global-button-container {
  height: 100px;
  width: 110px;
  cursor: grab !important;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
  margin: 0px 44px 23px 25px;
  z-index: 10000;
}

.ai-global-button-container.half-width {
  width: 55px !important;
  justify-content: end;
}

.casper-info-popup-container {
  max-width: 350px;
  position: fixed;
  right: -370px;
  bottom: 20vh;
  background-color: #fff;
  border-radius: 20px;
  margin-right: 20px;
  overflow: hidden;
  transition: right 0.5s ease-out;
  z-index: 1499;
}

.casper-info-popup-container.visible {
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.casper-info-popup-container .casper-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.casper-info-popup-container .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.casper-info-popup-container .popup-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.casper-info-popup-container .image-icon {
  width: 25px;
  margin-right: 20px;
}

.casper-info-popup-container .title {
  font-size: large;
  font-weight: 700;
  font-family: poppins;
}

.casper-info-popup-container .popup-content .text-content {
  font-weight: 500;
}
