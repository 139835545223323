.ticket-details-container {
    padding: 1em 2em;
    background-color: white;
    width: 100%;
    border-radius: 1em;
    margin-top: 1em;
  }
  .header {
    text-align: start;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .ticket-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    gap: 4em;
  }
  .ticket-tabs-header{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: .5em;
  }
 .details-title .details-text{
    display: flex;
    justify-content: start;
  }
  
  .ticket-card {
    display: flex;
    flex-direction: column;
    padding: 1em;
    border-radius: 1em;
    border: 1px solid #54C1045C;
    min-width: 25em;
    margin-top: 2em;
  }
  
  .messages-section {
    margin-top: 20px;
  }
  
  .messages-list {
    margin-top: 10px;
    width: 95%;
  }
  
  .message {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .timestamp {
    display: block;
    font-size: 12px;
    margin-top: 5px;
    color: gray;
  }
  
  .status {
    color: green;
    font-weight: bold;
  }
  
  .message-header{
    display: flex;
    justify-content: space-between;

  }
  .ticket-card .ant-card-body{
    padding: 0px;
  }.ticket-card {
    background-color: #f5f5f5; /* Same background for the whole card */
    padding: 16px;
    border-radius: 8px;
    height: 29.188em;
    width: 25.125em;
    background-color: white;
    border: 1px solid rgba(84, 193, 4, 1);
  }
  
  .ticket-details {
    list-style: none; /* Remove bullet points */
    padding: 0;
    margin: 0;
  }
  
  .ticket-details li {
    display: flex;
    justify-content: space-between;
    padding: 8px 0; /* Add spacing between rows */
  }
  
  .ticket-detail-data-label {
    color: #1C1C1E;
    font-size: 1em;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    margin: 0;
  }
  
  .ticket-detail-data-value {
    color: #1C1C1E;
    font-size: 1em;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    margin: 0;
  }
.ticket-card-hr{
  border: .5px solid rgb(214, 209, 209);
}

.message-end-hr{
  border: .35px solid rgba(84, 193, 4, 1);
  width: 33.75em;
  margin-left:3em;
}
.tickets-tabs .ant-tabs-tab {
  font-size: 1em !important;
  font-weight: bold !important;
}

.tickets-tabs .ant-tabs-content {
  padding: 10px !important;
}

.ticket-comment-avatar{
  width: 35px;
  height: 35px;
  font-size: 1.5em;
  font-weight: 600;
  background-color: #AABAC6 !important;
  display: flex !important;
  align-items: center !important;
}
.ticket-comment-user-title{
  margin-bottom: -5px;
  font-weight: 600 !important;
  cursor: pointer;
}
.ticket-user-comment{
  font-family: Poppins;
  font-size: .95em;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  margin: 0px;
  color: #8E8E93;
  padding-top: .5em;
}

.ticket-comment-overflow-container{
  overflow: auto;
  max-height: 45vh;
  padding-right: 10px;
}
.ticket-comment-overflow-container::-webkit-scrollbar {
  height: 4px;
  width: 4px !important;
  display:block;
}
.ticket-comment-overflow-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #fff;
  -moz-box-shadow:  inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff;
  border-radius: 10px;
  padding-right: 10px;
  margin-bottom:10px;
  margin-top:10px
  }
  
.ticket-comment-overflow-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#b1b0b0e1;
}
@media screen and (max-width: 1000px) {
    .ticket-content {
      flex-direction: column-reverse; /* Stack elements */
      align-items: center; /* Center them */
    }
    .tickets-tabs{
        width: 100%;
    }
}