@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-weight: bold;
}

h2 {
  font-weight: bold;
}

// custom styles
.HVCenter {
  height: 85vh;
  width: 95vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0 20px !important;
  padding: 0;
  overflow: hidden;
}
.error-bountry-title-head{
  margin-top: 1em;
  font-size: 2.25em;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-style: normal;
  color: #2E2C34;
  text-align: center;
  margin-bottom: 10px;
}
.error-boundry-btn,.error-boundry-btn:hover,.error-boundry-btn:focus {
  background-color: #D9D9D9;
  border-radius: .5em;
  width: 10em;
  padding: .5em 1em;
  color: #000000;
  font-family: Poppins;
  font-weight: 600;
  line-height: normal;
  font-style: normal;
  font-size: 1.5em;
  border: none;
  outline: none;
  display: flex;
  height: 3em !important;
  text-align: center !important;
  justify-content: center !important;
  margin: 1em;
}
.error-boundry-image {
  height: 50%;
}
.error-boundry-container{
  height: calc(100vh - 125px);
}
.error-permission-warning-text{
  color: #8D8D8D;
  font-family: Poppins;
  line-height: normal;
  font-size: 1.25em;
  font-weight: 400;
  margin: 0px;
}
@media (max-width:600px) {
  .error-boundry-image {
    height: 50%;
    width: 100%;
  }
}
@primary-color: #B0CB1F;@skeleton-color: #fff;@radio-dot-color: #881FCC;@slider-rail-background-color: #c9c9c9;