.tickets-list {
    padding: 1em;
    width: 100%;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
}

.ticket-form-select .ant-select-selector{
    border-radius: .5em !important;
    height: 3em !important;
    border: 1px solid rgba(84, 193, 4, 1) !important;
    box-shadow: none !important;
    width: 100%;
    min-width: 12em;
  }
  .ticket-form-select .ant-select-selection-item{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
    min-width: 12em;
  }
  .ticket-form-select .ant-select-selection-search-input{
    height: 2.5em !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    line-height: 2.75em !important;
    min-width: 12em;
  }
/* Custom ticket box */
.custom-ticket-box {
    background: #ffff;
    border: 1px solid  rgba(84, 193, 4, 1);
    border-radius: .5em;
    padding: 1em;
    margin-top: 1em;
}
/* Ticket header */
.ticket-header {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: .5em;
    flex-wrap: wrap;
}
.ticket-details{
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: .75em;
    padding:1em 0em !important;
}
.ticket-work-item-id{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    color: #2E2C34;
    margin:0px
}
.ticket-detail-title{
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    color: #2E2C34;
    margin:0px
}
.ticket-detail-description{
    font-family: Poppins;
    font-size: .9em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    color: #84818A;
    margin:0px
}
.ticket-status {
    color: #7F56D8;
    font-family: Poppins;
    font-size: .9em;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    text-decoration: underline;
    margin:0px;
}

/* Ticket footer */
.ticket-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    padding-top: .5em;
}

/* Status color based on priority */
.priority-high {
    background-color: red;
}

.priority-medium {
    background-color: purple;
}

.priority-low {
    background-color: yellow;
}

.status-resolved {
    background-color: green;
}

.status-ongoing {
    background-color: blue;
}

.status-new {
    background-color: yellow;
}

/* Pagination styling */
.ant-pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Styling for ticket actions */
.ticket-actions {
    margin-top: 12px;
    display: flex;
    gap: 10px;
}


.ticketFilterDropdowns{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;

}

.ticket-tab-title-inactive{
    font-size: 1.25em;
    font-family: Poppins;
    font-weight: 600;
    color: #84818A;
    font-style: normal;
    line-height: normal;
    margin: 0;
}
.ticket-tab-title-Active{
    font-size: 1.25em;
    font-family: Poppins;
    font-weight: 600;
    color: #34C759;
    font-style: normal;
    line-height: normal;
    margin: 0;
}

.ticket-sidebar-normal{
    padding: 1em 1em 0.5em 1em;
    cursor: pointer;
    height: 100%;
    border-bottom: 5px solid #E7E7E7;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.ticket-border-line{
    width: 100%;
    height: 100%;
    border-bottom: 5px solid #E7E7E7;
}
.ticket-sidebar-label{
    color: #84818A;
    font-size: 1.15em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    margin: 0px;
    font-family: Poppins;
}

.ticket-active-sidebar{
    /* border-bottom:5px solid #54C10461; */
    border-bottom:5px solid #34C759;
}

.ticket-active-sidebar-label{
    color: #34C759;
}
.no-ticket-found-text{
    font-size: 1.25em;
    font-family: Poppins;
    font-weight: 600;
    color: #84818A;
    font-style: normal;
    line-height: normal;
    margin: 0;
}
.ticket-list-container{
    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: #FFFFFF;
    border-radius: .5em;
    min-height: 67vh;
}


.thankyou-submit-btn, .thankyou-submit-btn:focus, .thankyou-submit-btn:hover{
    background-color: #D9D9D9;
    border-radius: .5em;
    padding: .5em 1em;
    color: #000000;
    font-family: Poppins;
    font-weight: 600;
    line-height: normal;
    font-style: normal;
    font-size: 1.5em;
    border: none;
    outline: none;
    height: 3em !important;
    text-align: center !important;
    margin: 1em;
    white-space: nowrap;
  }
.ticket-thank-you-title{
   font-family: Poppins;
   font-size: 1.5em;
   line-height: normal;
   color: #1C1C1E;
   text-align: center;
   margin:0;
   font-weight: 500;
}
.ticket-priority{
    padding: 5px 12px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 0.75rem;
}
.ticket-list-overflow-container{
    overflow: auto;
    max-height: 50vh;
    padding-right: 10px;
  }
  .ticket-list-overflow-container::-webkit-scrollbar {
    height: 4px;
    width: 4px !important;
    display:block;
  }
  .ticket-list-overflow-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    -moz-box-shadow:  inset 0 0 5px #fff;
    -webkit-box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
    padding-right: 10px;
    margin-bottom:10px;
    margin-top:10px
    }
    
  .ticket-list-overflow-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color:#b1b0b0e1;
  }
  .ticket-thank-you-navigation {
    font-family: Poppins;
    font-size: 1em;
    line-height: normal;
    color: #1C1C1E;
    text-align: center;
    margin:0;
    font-weight: 500;
  }
  .ticket-custom-pagination .ant-pagination-item{
    border: 1px solid #E7E7E7 !important;
    color: #2E2A40;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
  }
  .ticket-custom-pagination .ant-pagination-item-active{
    background-color: #54C104;
    color: #fff !important;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
  }
  .ticket-custom-pagination .ant-pagination-item-active a{
    color: #fff !important;
  }
  .ticket-custom-pagination .ant-pagination-prev a,.ticket-custom-pagination .ant-pagination-next a{
    color: #54C104;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
  }
  .ticket-custom-pagination .ant-pagination-disabled a{
    color: #B4B4B4;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 500;
  }
  .ticket-thankyou-banner-bg{
    width: 100%;
    height: 45%;
}
@media (max-width:660px) {
    .ticket-thankyou-banner-bg{
        width: 80%;
        height: auto;
    }
    .ticket-thankyou-page-container{
        height: calc(100vh - 200px) !important;
        width: 100%;
        padding: 1em;
        border-radius: 0px !important;
    }
}