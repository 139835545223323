.create-titcket-root {
  font-size: 16px;
  font-family: Poppins;
}
.ticket-form-container {
  width:100%;
  background: rgba(255, 255, 255, 1);
  border-radius: .5em;
  padding: 2em 3em;
  margin-top: 1em;
  }
.ticket-system-main-head {
  font-family: Poppins;
  font-weight: 600;
  font-size: 1.5em;
  font-style: normal;
  line-height: normal;
  color:rgba(46, 42, 64, 1);
  margin: 0;
}
  .form-input-row-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap:2em;
  }
  .ticket-form-input-section{
    width: 100%;
    max-width: 80%;
  }
  .ticket-attachment-form-item .ant-form-item-label{
    visibility:  hidden !important;
  }
  .ticket-form-input{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 1em !important;
    width:100%;
    line-height: 2.75em !important;
}
.ticket-form-item-width{
  width: 40%;
}
.ticket-form-select .ant-select-selector{
  border-radius: .5em !important;
  height: 3em !important;
  border: 1px solid rgba(84, 193, 4, 1) !important;
  box-shadow: none !important;
}
.ticket-form-select .ant-select-selection-item{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  line-height: 2.75em !important;
}
.ticket-form-select .ant-select-selection-search-input{
  height: 2.5em !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1em !important;
  line-height: 2.75em !important;
}
.custom-ticket-upload-btn {
  width:100% !important;
  display: flex;
  align-items: center;
  justify-content: start;
  gap:.5em;
  border: 1px solid rgba(84, 193, 4, 1);
  padding: .5em 1em;
  border-radius: .5em;
  height: 3em !important;
  cursor: pointer;
  transition: 0.3s;
}
.custom-upload-btn:hover {
  border: 1px solid rgba(84, 193, 4, 1);
}
.custom-ticket-upload-icon{
  height: 1.5em;
  width: 1.5em;
}
.custom-ticket-upload-btn span{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1.2em !important;
  line-height: normal !important;
}
.custom-ticket-upload .ant-upload{
  width: 100% !important;
}
.ticket-support-submit-btn, .ticket-support-submit-btn:hover, .ticket-support-submit-btn:focus{
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.2em;
  font-style: normal;
  line-height: normal;
  color: #ffffff;
  background: rgba(76, 203, 31, 1);
  border: 1px solid rgba(76, 203, 31, 1);
  border-radius: .5em;
  padding: .5em 2em;
  cursor: pointer;
  outline: none;
  border: none;
  height: 3em;
}
.ticket-support-cancel-btn, .ticket-support-cancel-btn:hover, .ticket-support-cancel-btn:focus{
  font-family: Poppins;
  font-weight: 500;
  font-size: 1.2em;
  font-style: normal;
  line-height: normal;
  color: rgba(137, 152, 162, 1);
  background: #ffffff;
  border-radius: .5em;
  padding: .5em 2em;
  cursor: pointer;
  outline: none;
  border: none;
  height: 3em;
  border: 1px solid rgba(170, 186, 198, 1)
}
.ticket-form-submit-button-container{
  padding-right: 1em;
  flex-wrap: wrap;
}
@media (max-width:1200px) {
  .ticket-form-input-section{
    max-width: 80%;
  }
  .ticket-form-submit-button-container{
    padding-right: 1em;
  }
}
@media (max-width:960px) {
  .ticket-form-input-section{
    max-width: 95%;
  }
  .ticket-form-submit-button-container{
    padding-right: 1em;
  }
  .ticket-form-item-width{
    width: 50%;
  }
  .ticket-form-container{
    padding: 2em 2em;
  }
}
@media (max-width:600px) {
  .form-input-row-container{
    flex-wrap: wrap;
    gap: 0em;
  }
  .ticket-form-input-section{
    max-width: 100%;
  }
  .ticket-form-item-width{
    width: 100%;
  }
  .custom-ticket-upload-btn{
    margin-top: 1em;
  }
  .ticket-form-submit-button-container{
    padding-right: 0em;
  }
  .ticket-form-container{
    padding: 1em 1em;
  }
}
